"use client";
import {
  GameAlignment,
  GameStatus,
  isGameClosed,
  isGameException,
  ScoreboardGame,
} from "common";
import React, { createContext, useContext, useState, useRef, useEffect } from "react";
import {
  DriveStatus,
  getDriveStatus,
  isGameLive,
  LiveGame,
} from "@/utils/game";
import { createGameDetailSlug } from "../url";
import { useScoreboardUpdate } from "../hooks/scoreboard-update";
import assert from "assert";

export interface GameContextType {
  game: ScoreboardGame;
  slug: string;
  prevStatus?: GameStatus;
  showCountdown: boolean;
  setShowCountdown: React.Dispatch<React.SetStateAction<boolean>>;
  startsNow: boolean;
  setStartsNow: React.Dispatch<React.SetStateAction<boolean>>;
  live?: LiveGame;
  closed: boolean;
  exception: boolean;
  winner: GameAlignment | null;
  driveStatus: DriveStatus | undefined;
}

const GameContext = createContext<GameContextType | null>(null);

export const useGame = () => {
  const context = useContext(GameContext);
  if (!context) {
    throw new Error("useGame must be used within a GameProvider");
  }
  return context;
};

export function GameProvider({
  game,
  children,
}: {
  game: ScoreboardGame;
  children: React.ReactNode;
}) {
  console.log("game provider re-render");
  assert(game, "A game must be provided");
  const [data, setData] = useState<ScoreboardGame>(game);
  useScoreboardUpdate(data, setData);
  const [prevStatus, setPrevStatus] = useState<GameStatus | undefined>(undefined);
  const [showCountdown, setShowCountdown] = useState<boolean>(false);
  const [startsNow, setStartsNow] = useState<boolean>(false);
  const statusRef = useRef<GameStatus | undefined>(undefined);
  
  useEffect(() => {
    setData(game);
  }, [game]);

  useEffect(() => {
    if (data.status !== statusRef.current) {
      setPrevStatus(statusRef.current);
      statusRef.current = data.status;
    }
  }, [data.status]);

  const live = isGameLive(data, prevStatus);

  const exception = isGameException(data.status);
  const closed = isGameClosed(data.status);
  const winner: GameAlignment | null =
    !exception && closed
      ? data.awayScore > data.homeScore
        ? "away"
        : "home"
      : null;

  const driveStatus = getDriveStatus(data);
  return (
    <GameContext.Provider
      value={{
        game: data,
        slug: createGameDetailSlug(data.league, data.id),
        prevStatus,
        showCountdown,
        setShowCountdown,
        startsNow,
        setStartsNow,
        live,
        exception,
        closed,
        winner,
        driveStatus,
      }}
    >
      {children}
    </GameContext.Provider>
  );
}
