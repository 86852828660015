"use client";
import Button from "@/components/primitives/button";
import Icon from "@/components/primitives/icon";
import styles from "./cell.module.css";
import {
  BettorPickSlipPick,
  GameAlignment,
  GameBasic,
  GameStatus,
  LineType,
  Odd2,
  OddOutcome,
  OddVariant,
  RecentPick,
  isGameClosed,
  isGameException,
  lineToPickType,
  toSportType,
} from "common";
import { buildOdd } from "common";
import { formatOdd } from "@/utils";
import { useContext, useEffect, useRef, useState } from "react";
import { CreatedPickTooltip } from "@/components/primitives/tooltip";
import { useOddsFormat } from "@/utils/providers/odds-format";
import { usePickSlip } from "@/components/pick/create-pick";
// import { usePickSlip } from "@/utils/providers/pick-slip";

export type OddCellColor = "default" | "dark" | "light" | "neutral";
export interface OddsCellProps {
  alignment: GameAlignment;
  game: GameBasic;
  className?: string;
  color?: OddCellColor;
  hideCheckmark?: boolean;
  hidePickStatus?: boolean;
  odd: Odd2 | undefined;
  againstOdd: Odd2 | undefined;
  startsNow?: boolean;
}

export default function OddsCell(props: OddsCellProps) {
  const { oddsFormat } = useOddsFormat();

  const pickAnimationElement = useRef<HTMLDivElement>(null);
  const { addPick, getPickStatus, changePickAlignment, removePick } = usePickSlip();
  const [addingPick, setAddingPick] = useState(false);
  // const odd: Odd | undefined = buildOdd(
  //   props.odd,
  //   props.alignment,
  //   props.game.odds
  // );
  // const againstOdd: Odd | undefined = buildOdd(
  //   props.odd,
  //   props.alignment === "away" ? "home" : "away",
  //   props.game.odds
  // );
  const addPickDetails: RecentPick | undefined =
    props.odd && props.againstOdd
      ? {
          type: lineToPickType(props.odd.type, props.odd.alignment),
          line: props.odd.type,
          alignment: props.odd.alignment,
          sport_type: toSportType(props.game.league),
          game: {
            global_game_id: props.game.id,
            date_time_utc: props.game.dateTime,
            status: props.game.status,
            platform: props.game.platform,
            teams: {
              0: {
                id: props.game.awayTeam.id,
                name: props.game.awayTeam.name,
                location: props.game.awayTeam.location,
                abbreviation: props.game.awayTeam.abbreviation,
                primaryColor: props.game.awayTeam.primaryColor,
                logoUrl: props.game.awayTeam.logoUrl,
                slug: `${props.game.awayTeam.location}-${props.game.awayTeam.name}`,
              },
              1: {
                id: props.game.homeTeam.id,
                name: props.game.homeTeam.name,
                location: props.game.homeTeam.location,
                abbreviation: props.game.homeTeam.abbreviation,
                primaryColor: props.game.homeTeam.primaryColor,
                logoUrl: props.game.homeTeam.logoUrl,
                slug: `${props.game.homeTeam.location}-${props.game.homeTeam.name}`,
              },
            },
          },
          odds: {
            0: props.alignment === "away" ? props.odd : props.againstOdd,
            1: props.alignment === "home" ? props.odd : props.againstOdd,
          },
        }
      : undefined;

  const pickStatus = getPickStatus(addPickDetails);

  const exception: boolean = isGameException(props.game.status);
  const closed: boolean = isGameClosed(props.game.status);

  const loss: boolean =
    !exception &&
    props.odd?.outcome !== OddOutcome.Win &&
    closed &&
    !props.hideCheckmark;
  const showCheckmark: boolean =
    !exception &&
    props.odd?.outcome === OddOutcome.Win &&
    closed &&
    !props.hideCheckmark;

  function handlePickClick(e: any) {
    e.preventDefault();
    e.stopPropagation();
    console.log("pick status:", pickStatus);

    if (pickStatus.created) {
      return;
    } else if (pickStatus.added) {
      removePick(addPickDetails);
      return;
    } else if (pickStatus.opposing.added) {
      changePickAlignment(addPickDetails);
      return;
    } else if (!pickStatus.added && !pickStatus.opposing.added) {
      addPick(addPickDetails, pickAnimationElement);

      setAddingPick(true);
      setTimeout(() => {
        setAddingPick(false);
        return;
      }, 700);
    }
  }

  const oddNotDefined =
    !props.odd ||
    props.odd === undefined ||
    props.odd.payout === undefined ||
    props.odd.payout === 0 ||
    props.odd.payout === null ||
    props.odd.value === 0 ||
    props.odd.value === null;
  const disabled =
    props.game.status === "InProgress" ||
    closed ||
    oddNotDefined ||
    pickStatus.created ||
    pickStatus.opposing.created ||
    props.hidePickStatus ||
    props.startsNow;

  const added: boolean =
    (pickStatus.added || addingPick) && props.game.status === "Scheduled";

  const formattedOdd = formatOdd(oddsFormat, props.odd);

  return (
    <div className="relative" ref={pickAnimationElement}>
      <Button
        custom
        disabled={disabled}
        unstyled
        onClick={handlePickClick}
        data-color={props.color || "default"}
        data-loss={loss ? "true" : undefined}
        data-added={added ? "true" : undefined}
        className={`${styles.root} ${props.className}`}
      >
        {props.odd === undefined ||
        props.odd.payout === undefined ||
        props.odd.payout === 0 ||
        props.odd.payout === null ||
        props.odd.value === 0 ||
        props.odd.value === null ? (
          <div className="w-full text-label-lg-bold text-secondary-600 text-center">
            TBD
          </div>
        ) : (
          <>
            {showCheckmark && <Checkmark />}
            <span className="normal-case tracking-[0.06px]">
              {formattedOdd?.type === LineType.Moneyline
                ? formattedOdd?.payout
                : formattedOdd?.value}
            </span>
            <div className={`${styles.payout}`}>
              {props.odd.type !== LineType.Moneyline &&
                !isGameClosed(props.game.status) && (
                  <span className="normal-case">
                    {formattedOdd?.type === LineType.Moneyline
                      ? undefined
                      : formattedOdd?.payout}
                  </span>
                )}
            </div>
          </>
        )}
      </Button>
      {!props.hidePickStatus &&
        pickStatus.created &&
        props.game.status === "Scheduled" && (
          <CreatedPickTooltip
            position={props.alignment === "away" ? "top" : "bottom"}
          >
            You picked this odd
          </CreatedPickTooltip>
        )}
    </div>
  );
}


function Checkmark() {
  return (
    <div className="rounded-full bg-primary-300 min-w-[16px] h-4 flex items-center justify-center">
      <Icon name="checkmark" size="xs" className="fill-white" />
    </div>
  );
}
